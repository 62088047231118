import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import axios from "axios";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export const state = () => ({
  is_dark_mode: true,
  user: null,
  organization: null,
});

export const mutations = {
  TOGGLE_DARK_MODE(state) {
    state.is_dark_mode = !state.is_dark_mode;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_ORGANIZATION(state, payload) {
    state.organization = payload;
  },
};

export const actions = {
  async fetchUser({ commit }) {
    let {
      data: { data: user },
    } = await axios.get("/api/user-info");

    commit("SET_USER", user);
  },
  async fetchOrganization({ commit }) {
    let {
      data: { data: organization },
    } = await axios.get("/api/organization-info");

    commit("SET_ORGANIZATION", organization);
  },
  toggleDarkMode({ commit }) {
    commit("TOGGLE_DARK_MODE");
  },
};

export const getters = {
  is_dark_mode: (state) => {
    return state.is_dark_mode;
  },
  user: (state) => {
    return state.user;
  },
  organization: (state) => {
    return state.organization;
  },
};

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  state: { ...state },
  getters: { ...getters },
  actions: { ...actions },
  mutations: { ...mutations },
  plugins: [vuexLocal.plugin],
  strict: debug,
});
