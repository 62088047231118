import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const DEFAULT_META = { title: "EDO-IZER" };

Vue.use(VueRouter);

const routes = [
  {
    component: () => import("../views/login.vue"),
    name: "Login",
    path: "/login",
    meta: { ...DEFAULT_META },
  },
  {
    component: () => import("../views/error.vue"),
    name: "Error",
    path: "/error",
    meta: { ...DEFAULT_META },
  },
  {
    component: () => import("../views/signup.vue"),
    name: "Signup",
    path: "/signup",
    meta: { ...DEFAULT_META },
  },
  {
    component: () => import("../layouts/default.vue"),
    name: "Root",
    path: "/",
    meta: { ...DEFAULT_META },
    redirect: "/home",
    children: [
      {
        component: () => import("../views/index.vue"),
        name: "Home",
        path: "/home",
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/organization/index.vue"),
        name: "Organization",
        path: "/organization",
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/objects/index.vue"),
        name: "Objects",
        path: "/objects",
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/objects/_object.vue"),
        name: "Object Record",
        path: "/objects/:object",
        props: true,
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/extractions/index.vue"),
        name: "Extractions",
        path: "/extractions",
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/extractions/_id.vue"),
        name: "Extraction",
        path: "/extractions/:id",
        props: true,
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/permissions/index.vue"),
        name: "Permissions",
        path: "/permissions",
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/permissions/_id.vue"),
        name: "Permission Record",
        path: "/permissions/:id",

        props: true,
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/records/index.vue"),
        name: "Records",
        path: "/records",
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/records/_id.vue"),
        name: "Records",
        path: "/records/:id",
        props: true,
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/creationOrders/index.vue"),
        name: "Creation Orders",
        path: "/creationOrders",
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/records/_id.vue"),
        name: "Creation Order",
        path: "/creationOrders/:id",
        props: true,
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/deletionOrders/index.vue"),
        name: "Deletion Orders",
        path: "/deletionOrders",
        meta: { ...DEFAULT_META },
      },
      {
        component: () => import("../views/test/index.vue"),
        name: "Data Testing",
        path: "/test",
        meta: { ...DEFAULT_META },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: __dirname,
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log("beforeEach");
  if (to.name === "Login") return next();

  try {
    await Vue.axios.get("/auth/verify");
    next();
  } catch (error) {
    if (error.response.status === 401) return next({ name: "Login" });
  }
});

export default router;
