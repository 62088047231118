import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";

import "material-design-icons-iconfont/dist/material-design-icons.css";

import axios from "axios";
import VueAxios from "vue-axios";

import Vuetify from "vuetify";
Vue.use(Vuetify);
import "vuetify/dist/vuetify.min.css";

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.config.debug = false;
Vue.config.silent = true;

const vuetify = new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#032e61",
        secondary: "#5db7f1",
        accent: "#3d867d",
        error: "#b71c1c",
      },
      dark: {
        primary: "#032e61",
        secondary: "#5db7f1",
        accent: "#3d867d",
        error: "#b71c1c",
      },
    },
  },
});

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

new Vue({
  axios,
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
